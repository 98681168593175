/* eslint-disable no-process-env */
export default {
  PUBLIC_ENABLE_API_PROXY: process.env.NEXT_PUBLIC_ENABLE_API_PROXY,
  PUBLIC_API_END_POINT: process.env.NEXT_PUBLIC_API_END_POINT,
  PUBLIC_APP_DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN || 'https://freec.asia',
  PUBLIC_LINK_URL_TERMS: process.env.NEXT_PUBLIC_LINK_URL_TERMS || '',
  PUBLIC_LINK_URL_POLICY: process.env.NEXT_PUBLIC_LINK_URL_POLICY || '',
  PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  PUBLIC_APP_ENVIRONMENT: process.env.NEXT_PUBLIC_APP_ENVIRONMENT,
  PUBLIC_CHAT_WITH_US_IFRAME: process.env.NEXT_PUBLIC_CHAT_WITH_US_IFRAME,
  PUBLIC_GOOGLE_APP_ID: process.env.NEXT_PUBLIC_GOOGLE_APP_ID,
  PUBLIC_FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
  SENTRY_PROJECT: process.env.SENTRY_PROJECT,
  PUBLIC_NO_INDEX: process.env.NEXT_PUBLIC_NO_INDEX,
  PUBLIC_ENABLE_LIVE_CHAT: process.env.NEXT_PUBLIC_ENABLE_LIVE_CHAT || '',
  PUBLIC_GTM_KEY: process.env.NEXT_PUBLIC_GTM_KEY,
  PUBLIC_FOOTER_ABOUT_US: process.env.NEXT_PUBLIC_FOOTER_ABOUT_US || '',
  PUBLIC_FOOTER_TERMS: process.env.NEXT_PUBLIC_FOOTER_TERMS || '',
  PUBLIC_FOOTER_PRIVACY: process.env.NEXT_PUBLIC_FOOTER_PRIVACY || '',
  PUBLIC_FOOTER_JOB_OPPORTUNITY: process.env.NEXT_PUBLIC_FOOTER_JOB_OPPORTUNITY || '',
  PUBLIC_FOOTER_CONTACT: process.env.NEXT_PUBLIC_FOOTER_CONTACT || '',
  PUBLIC_SUPPORT_CONTACT: process.env.NEXT_PUBLIC_SUPPORT_CONTACT || '',
  PUBLIC_HOTLINE_CANDIDATE: process.env.NEXT_PUBLIC_HOTLINE_CANDIDATE || '',
  PUBLIC_EMPLOYER_APP_DOMAIN: process.env.NEXT_PUBLIC_EMPLOYER_APP_DOMAIN || '',
  PUBLIC_FOOTER_ABOUT_COMPANY_SLUG: process.env.NEXT_PUBLIC_FOOTER_ABOUT_COMPANY_SLUG || '',
  PUBLIC_NUMBER_CANDIDATES: process.env.NEXT_PUBLIC_NUMBER_CANDIDATES || '',
  PUBLIC_NUMBER_COMPANIES: process.env.NEXT_PUBLIC_NUMBER_COMPANIES || '',
  PUBLIC_NUMBER_NEW_CANDIDATES: process.env.NEXT_PUBLIC_NUMBER_NEW_CANDIDATES || '',
  PUBLIC_NUMBER_CURRENT_JOBS: process.env.NEXT_PUBLIC_NUMBER_CURRENT_JOBS,
  PUBLIC_CRISP_WEBSITE_ID: process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID,
  PUBLIC_ENABLE_LIVE_CHAT_USER_TYPE: process.env.NEXT_PUBLIC_ENABLE_LIVE_CHAT_USER_TYPE,
  NEXT_PUBLIC_ENABLE_GLEAP: process.env.NEXT_PUBLIC_ENABLE_GLEAP,
  PUBLIC_CV_EXAMPLES_END_POINT: process.env.NEXT_PUBLIC_CV_EXAMPLES_END_POINT
}
